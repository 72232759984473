import { monaco } from 'react-monaco-editor';
import { deepCopy } from '../controls/forms/formFunctions';
import { replaceAll } from './globalMethods';
import Json2Yaml from './json2Yaml';

const convertObjectToYaml = function (obj) {
    var json = obj;
    if (typeof json == 'string') {
        return json;
    }

    var yaml = new Json2Yaml();
    var str = yaml.convertJson(json);
    str = decodeURIComponent(str);
    return str;
};

export const registerCompletionItems = function (portal, completionItems, configs, globalIncludes) {
    var includes = {};
    var appSettingSuggestions = [];
    var propertySettingsSuggestions = [];
    var methodSuggestions = [];
    // var subDomainReplacements = {};

    if (globalIncludes == null) globalIncludes = [];

    if (completionItems?.appSettings != null) {
        completionItems?.appSettings.forEach((name) => {
            appSettingSuggestions.push({
                page: null,
                label: name,
                kind: monaco.languages.CompletionItemKind.Field,
                insertText: name,
            });
        });
    }
    if (completionItems?.propertySettings != null) {
        completionItems?.propertySettings.forEach((name) => {
            propertySettingsSuggestions.push({
                page: null,
                label: name,
                kind: monaco.languages.CompletionItemKind.Field,
                insertText: name,
            });
        });
    }

    if (completionItems?.methods != null) {
        completionItems?.methods.forEach((method) => {
            //            if (!replacementSuggestions.filter((p) => config.label == name).length > 0) {
            methodSuggestions.push({
                page: null,
                label: method.name,
                kind: monaco.languages.CompletionItemKind.Function,
                insertText: method.nameWithParams,
            });
            //           }
        });
    }

    var environments = {
        default: {
            suggestions: [],
            strategySuggestions: [],
            connectorSuggestions: [],
            replacementSuggestions: [],
            pipeSuggestions: [],
            keySuggestions: [],
            configs: {},
        },
    };

    var getEnvName = (key) => {
        const parts = key.split('_');
        return parts.slice(0, 3).join('_');
    };
    for (const key in configs) {
        var environment = null;
        var p = configs[key];
        if (p == null) {
            // may be an empty file (TODO update api not to send)
            continue;
        }

        environment = environments.default;
        if (key.indexOf('subdomain') === 0 || key.indexOf('community') === 0) {
            var environmentName = getEnvName(key);
            if (environments[environmentName] == null)
                environments[environmentName] = {
                    suggestions: [],
                    strategySuggestions: [],
                    connectorSuggestions: [],
                    replacementSuggestions: [],
                    keySuggestions: [],
                    pipeSuggestions: [],
                    configs: {},
                };

            environment = environments[environmentName];
        }

        environment.configs[key] = p;
        // createSuggestions(key, p, environment);

        if (!p.formSections) continue;

        var itemIncludes = p.includes;
        if (itemIncludes == null) itemIncludes = [];
        includes[key] = replaceAll([...globalIncludes, ...itemIncludes, key].join('|') + '|', '.', '_');
        // eslint-disable-next-line no-loop-func
        p.formSections.forEach((fs) => {
            if (!fs.key || fs.key.startsWith('_')) return;

            var insertTextArray = [` ${fs.key}`];

            if (fs?.value?.replacements || fs?.value?.defaults) {
                insertTextArray.push('  replacements:');
                if (fs.value.defaults) {
                    for (const rKey in fs.value.defaults) {
                        insertTextArray.push('    ' + `${rKey}: ${fs.value.defaults[rKey]}`);
                    }
                }
                for (const rKey in fs.value.replacements) {
                    insertTextArray.push('    ' + `${rKey}: ${fs.value.replacements[rKey]}`);
                }
            }

            environment.suggestions.push({
                page: key,
                label: fs.key,
                kind: monaco.languages.CompletionItemKind.Function,
                // documentation: 'Tabs stuff',
                insertText: insertTextArray.join('\r\n'),
            });
        });
    }

    portal.intellisense = {
        environments: environments,
        appSettingSuggestions,
        propertySettingsSuggestions,
        methodSuggestions,
        includes,
    };

    monaco.languages.registerCompletionItemProvider('yaml', {
        provideCompletionItems: function (model, position) {
            // find out if we are completing a property in the 'dependencies' object.
            var textUntilPosition = model.getValueInRange({
                startLineNumber: position.lineNumber,
                startColumn: 1,
                endLineNumber: position.lineNumber,
                endColumn: position.column,
            });

            var envName = 'default';
            if (model.id.indexOf('subdomain') === 0 || model.id.indexOf('community') === 0) {
                envName = getEnvName(model.id);
            }

            var completionItems3 = getCompletionItems(portal, envName);

            for (let index = 0; index < completionItems3.length; index++) {
                const item = completionItems3[index];
                var match = textUntilPosition.match(item.regEx);
                if (match) {
                    var filteredSuggestions = item.suggestions;

                    filteredSuggestions = item.suggestions;

                    // if (model.id.indexOf('subdomain') === 0 || model.id.indexOf('community') === 0) {
                    //     filteredSuggestions = item.suggestions.filter((s) => {
                    //         return s.page == null || s.page.indexOf(model.id) === 0;
                    //     });
                    // } else {
                    //     filteredSuggestions = item.suggestions.filter((s) => {
                    //         return s.page == null || s.page.indexOf('subdomain') === -1;
                    //     });
                    // }

                    // TODO: ensure key is all lowercase before inserting into
                    let page = window?.dataStore?.pageDetails?.key?.toLowerCase();
                    if (page == null) return { suggestions: deepCopy(filteredSuggestions) };

                    while (page.includes('.')) page = page.replace('.', '_');

                    // if (page.indexOf('proxies') > -1) page = 'system';
                    if (page.split()) var includeStr = includes[page];
                    if (includeStr == null) {
                        page = page.split('_')[0];
                        includeStr = includes[page];
                    }

                    console.log(`Include: ${includeStr}`);
                    if (includeStr == null) {
                        debugger;
                        return null;
                    }

                    includeStr = includes[page];

                    // eslint-disable-next-line no-loop-func
                    const filtered = filteredSuggestions.filter((s) => {
                        return (
                            s.page == null ||
                            includeStr.indexOf(s.page + '|') > -1 ||
                            s.page.indexOf('base_') === 0 ||
                            s.page.indexOf('extensions_') === 0
                        );
                    });

                    return { suggestions: deepCopy(filtered) };
                }
            }

            return { suggestions: [] };
        },
    });
};

const getCompletionItems = function (portal, subdomainId) {
    var completionItems2 = portal.intellisense.environments[subdomainId];

    // debugger;

    completionItems2.connectorSuggestions = [];
    completionItems2.pipeSuggestions = [];
    completionItems2.strategySuggestions = [];

    for (var key in completionItems2.configs) {
        createSuggestions(key, completionItems2.configs[key], completionItems2);
    }

    var rawCompletionItems = [
        // {
        //     regEx: /validation\s*:\s*?$/,
        //     filter: false,
        //     suggestions: [
        //         {
        //             label: 'zeroBounceEmail',
        //             kind: monaco.languages.CompletionItemKind.Function,
        //             documentation: '',
        //             insertText: [' zeroBounceEmail'].join('\r\n'),
        //         },
        //     ],
        // },
        {
            regEx: 'connector\\s*:s*?$',
            filter: true,
            suggestions: completionItems2.connectorSuggestions,
        },
        {
            regEx: '  -\\s*?$',
            filter: true,
            suggestions: [
                {
                    page: null,
                    label: 'EndPoint (POST)',
                    kind: monaco.languages.CompletionItemKind.Function,
                    insertText: ' routeTemplate: api/\n  routeMethod: POST\n  routeOpenApi:\n  pipeline:\n    - pipe:',
                },
                {
                    page: null,
                    label: 'EndPoint (GET)',
                    kind: monaco.languages.CompletionItemKind.Function,
                    insertText: ' routeTemplate: api/\n  routeMethod: GET\n  routeOpenApi:\n  pipeline:\n    - pipe:',
                },
                {
                    page: null,
                    label: 'EndPoint (DELETE)',
                    kind: monaco.languages.CompletionItemKind.Function,
                    insertText: ' routeTemplate: api/\n  routeMethod: DELETE\n  routeOpenApi:\n  pipeline:\n    - pipe:',
                },
                {
                    page: null,
                    label: 'EndPoint (PATCH)',
                    kind: monaco.languages.CompletionItemKind.Function,
                    insertText: ' routeTemplate: api/\n  routeMethod: PATCH\n  routeOpenApi:\n  pipeline:\n    - pipe:',
                },
                {
                    page: null,
                    label: 'Key (Pipe)',
                    kind: monaco.languages.CompletionItemKind.Function,
                    insertText:
                        ' key: \n  value:\n    pipe: Variant.Core.Simple.DefaultScopedPipe\n    replacements:\n      CAN_EXECUTE_EXPRESSION:\n    defaults:\n      SCOPED_PIPES:\n        - pipe:',
                },
                {
                    page: null,
                    label: 'Key (Connector)',
                    kind: monaco.languages.CompletionItemKind.Function,
                    insertText: ' key: \n  value:\n    connector:',
                },
            ],
        },
        {
            regEx: 'routeOpenApi\\s*:s*?$',
            filter: true,
            suggestions: [
                {
                    page: null,
                    label: 'Default',
                    kind: monaco.languages.CompletionItemKind.Function,
                    insertText:
                        "\n  tags: [ Default ]\n  operationId: \n  summary: \n  description: \n  security: [ BearerToken: [] ]\n  request: { }\n  parameters: { }\n  requestBody:\n    required: true\n    content:\n      application/json:\n        schema:\n          $ref: '#/components/schemas/'\n  responses:\n    204: { description: NoContent  }\n    400: ${Defaults.ValidationError}\n    401: ${Defaults.AuthorizationError}",
                },
            ],
        },
        {
            regEx: 'pipe\\s*:s*?$',
            filter: true,
            suggestions: completionItems2.pipeSuggestions,
        },
        {
            regEx: 'strategy\\s*:s*?$',
            filter: true,
            suggestions: completionItems2.strategySuggestions,
        },
        {
            regEx: '\\$\\{.*\\.',
            filter: false,
            suggestions: portal.intellisense.methodSuggestions,
        },
        {
            regEx: '\\$\\{',
            filter: false,
            suggestions: portal.intellisense.propertySettingsSuggestions,
        },
        {
            regEx: '\\$\\[',
            filter: false,
            suggestions: portal.intellisense.appSettingSuggestions,
        },
        {
            regEx: '.*STRATEGY\\s*:s*?$',
            filter: true,
            suggestions: completionItems2.strategySuggestions,
        },
        {
            regEx: 'form[Ss]ection\\s*:s*?$',
            filter: true,
            suggestions: completionItems2.suggestions,
        },
        {
            regEx: /endpoint\s*:\s*?$/,
            filter: false,
            suggestions: [
                {
                    label: 'endpoint',
                    kind: monaco.languages.CompletionItemKind.Function,
                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                    documentation: '',
                    insertText: [
                        ' endpoint',
                        '$[TAB]name: ${1:name}',
                        '$[TAB]routeTemplate: /test/{name}',
                        '$[TAB]routeMethod: POST',
                        '$[TAB]namespace: http',
                        '$[TAB]disabled: ',
                        '$[TAB]request: ',
                        '$[TAB]response: ',
                        '$[TAB]roles: ',
                        '$[TAB]pipeline:',
                        '$[TAB]\t- pipe:',
                        '',
                    ].join('\r\n'),
                },
            ],
        },
        {
            regEx: /type\s*:\s*?$/,
            filter: false,
            suggestions: [
                {
                    label: 'div',
                    kind: monaco.languages.CompletionItemKind.Function,
                    documentation: '',
                    insertText: [' div', 'items:', '- '].join('\r\n'),
                },
                {
                    label: 'setProperties',
                    kind: monaco.languages.CompletionItemKind.Function,
                    documentation: '',
                    insertText: [' setProperties', 'settings:', '  '].join('\r\n'),
                },
                {
                    label: 'stack',
                    kind: monaco.languages.CompletionItemKind.Function,
                    documentation: '',
                    insertText: [' stack', 'items:', '- '].join('\r\n'),
                },
                {
                    label: 'label',
                    kind: monaco.languages.CompletionItemKind.Function,
                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                    documentation: '',
                    insertText: [' label', 'header: ${1:header}'].join('\r\n'),
                },
                {
                    label: 'paragraph',
                    kind: monaco.languages.CompletionItemKind.Function,
                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                    documentation: '',
                    insertText: [' p', 'header: ${1:header}'].join('\r\n'),
                },
                {
                    label: 'endpoint',
                    kind: monaco.languages.CompletionItemKind.Function,
                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                    documentation: '',
                    insertText: [
                        ' endpoint',
                        '$[TAB]name: ${1:name}',
                        '$[TAB]routeTemplate: /test/{name}',
                        '$[TAB]routeMethod: POST',
                        '$[TAB]namespace: http',
                        '$[TAB]disabled: ',
                        '$[TAB]request: ',
                        '$[TAB]response: ',
                        '$[TAB]roles: ',
                        '$[TAB]pipeline:',
                        '$[TAB]\t- pipe:',
                        '',
                    ].join('\r\n'),
                },
                {
                    label: 'collection',
                    kind: monaco.languages.CompletionItemKind.Function,
                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                    documentation: '',
                    insertText: [
                        ' collection',
                        '$[TAB]key: ${1:dataStoreKey}',
                        '$[TAB]settings:',
                        '$[TAB]\tformSection: ${2:formSectionName}',
                        '$[TAB]\tparentTag:',
                        '$[TAB]\tchildTag:',
                        '$[TAB]\tchildClass:',
                        '$[TAB]\tchildStyles:',
                        '',
                    ].join('\r\n'),
                },
                {
                    label: 'properties',
                    kind: monaco.languages.CompletionItemKind.Function,
                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                    documentation: '',
                    insertText: [
                        ' properties',
                        '$[TAB]key: ds.${1:dataStoreKey}',
                        '$[TAB]settings:',
                        '$[TAB]\tisInline:true',
                        '$[TAB]\tcolumns: 2',
                        '$[TAB]\tignores: []',
                        '$[TAB]\trenames: []',
                        '$[TAB]\treplacements: {}',
                        '$[TAB]\tchildStyles: {}',
                        '$[TAB]\tusePropertyNames: {}',
                        '',
                    ].join('\r\n'),
                },
                {
                    label: 'addLink',
                    kind: monaco.languages.CompletionItemKind.Function,
                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                    documentation: '',
                    insertText: [
                        ' addLink',
                        '$[TAB]property: ${1:property}',
                        '$[TAB]header: ${2:AddLinkHeader}',
                        '$[TAB]settings:',
                        '$[TAB]\tdefaultItems: 0',
                        '$[TAB]\tcontainer: ${3:containerName}',
                        '$[TAB]\tformSection: ',
                        '$[TAB]\t  type: div',
                        '$[TAB]\t\titems: ',
                        '$[TAB]\t\t\t- { type:  p, header: Available replacements and substitutions}',
                        "$[TAB]\t\t\t- { type:  p, header: '* pARENT_COLLECTION_PREFIX: PARENT_COLLECTION_PREFIX'}",
                        "$[TAB]\t\t\t- { type:  p, header: '* pARENT_COLLECTION_PROPERTY: PARENT_COLLECTION_PROPERTY'}",
                        "$[TAB]\t\t\t- { type:  p, header: '* pARENT_COLLECTION_INDEX: PARENT_COLLECTION_INDEX'}",
                        "$[TAB]\t\t\t- { type:  p, header: '* pARENT_COLLECTION_COUNTER: PARENT_COLLECTION_COUNTER'}",
                        "$[TAB]\t\t\t- { type:  p, header: '* uNIQUE_ID: UNIQUE_ID'}",
                        "$[TAB]\t\t\t- { type:  p, header: '* Items count _[stack.property]Count <- Substitution Variable '}",
                        "$[TAB]\t\t\t- { type:  p, header: '* Items count index _[stack.property]Index <- Substitution Variable '}",
                        '$[TAB]\t\t\t- type: textBox ',
                        '$[TAB]\t\t\t  property: propName',
                        '$[TAB]\t\t\t  header: Your data',
                        '$[TAB]\t\t\t  # THIS SHOULD ALWAYS BE LOWERCASE',
                        '$[TAB]\t\t\t- type: removelink',
                        '$[TAB]\t\t\t  icon: delete',
                        '$[TAB]\t\t\t  header: DeleteMe',
                        '',

                        '# MOVE THIS TO WHEREVER YOU NEED THE LIST DISPLAYED',
                        '- type: stack',
                        // '$[TAB]items: []',
                        "$[TAB]header: ''",
                        '$[TAB]key: ${3:containerName}',
                        '$[TAB]property: ${6:containerProperty}',
                        '',
                    ].join('\r\n'),
                },
            ],
        },
    ];
    var replaceTabs = (str, asTab) => {
        while (str.indexOf('$[TAB]') > -1) {
            str = str.replace('$[TAB]', asTab ? '\t' : '');
        }

        return str;
    };

    var completionItems = [];
    rawCompletionItems.forEach((item, n) => {
        var copy = deepCopy(item);
        copy.regEx = '- ' + copy.regEx;
        copy.suggestions.forEach((s) => {
            s.insertText = replaceTabs(s.insertText, true);
        });

        completionItems.push(copy);

        item.suggestions.forEach((s) => {
            s.insertText = replaceTabs(s.insertText, false);
        });

        completionItems.push(item);
    });

    return completionItems;
};

export const addConnectors = function (connectors, page, connectorSuggestions) {
    connectors.forEach((fs) => {
        if (!fs.key || fs.key.startsWith('_')) return;

        var insertTextArray = [` ${fs.key}`];
        if (!fs.value) return; // bad yaml
        if (fs.value.replacements) {
            // insertTextArray.push('$[TAB]replacements:');

            Object.keys(fs.value.replacements).forEach((rKey) => {
                var text = convertObjectToYaml(fs.value.replacements[rKey]);
                insertTextArray.push(`  ${rKey}: ${text === 'null' ? '' : text}`);
            });

            insertTextArray.push('$[TAB]pipeline:');
            insertTextArray.push('$[TAB]\t- pipe:');
        }

        // var page = key;
        var split = page.split('_');
        if (split.length > 0 && split[0] === split[1]) {
            page = split[0];
        }
        // if (key.indexOf('proxies') > -1) {
        //     page = 'system';
        // }

        var con = {
            page: page,
            label: fs.key,
            kind: monaco.languages.CompletionItemKind.Function,
            // documentation: 'Tabs stuff',
            insertText: insertTextArray.join('\r\n'),
        };

        // if (currentSubDomain != null) {
        //     currentSubDomain.connectorSuggestions.push(con);
        //     return;
        // }

        connectorSuggestions.push(con);
    });
};

const createSuggestions = function (key, config, environment) {
    if (config == null) return;
    if (config.connectors != null && config.connectors && config.connectors.length > 0) {
        // eslint-disable-next-line no-loop-func
        addConnectors(config.connectors, key, environment.connectorSuggestions);
    }

    if (config.pipes != null && config.pipes.length > 0) {
        // eslint-disable-next-line no-loop-func
        config.pipes.forEach((fs) => {
            if (!fs.key || fs.key.startsWith('_')) return;

            var insertTextArray = [` ${fs.key}`];
            if (!fs.value) return; // bad yaml
            if (fs.value.replacements) {
                // insertTextArray.push('replacements:');

                Object.keys(fs.value.replacements).forEach((rKey) => {
                    var text = convertObjectToYaml(fs.value.replacements[rKey]);
                    insertTextArray.push(`  ${rKey}: ${text === 'null' ? '' : text}`);
                });
            }

            var page = key;
            var split = key.split('_');
            if (split.length > 0 && split[0] === split[1]) {
                page = split[0];
            }
            // if (key.indexOf('proxies') > -1) {
            //     page = 'system';
            // }

            var pipe = {
                page: page,
                label: fs.key,
                kind: monaco.languages.CompletionItemKind.Function,
                // documentation: 'Tabs stuff',
                insertText: insertTextArray.join('\r\n'),
            };

            // if (currentSubDomain != null) {
            //     currentSubDomain.pipeSuggestions.push(pipe);
            //     return;
            // }

            environment.pipeSuggestions.push(pipe);
        });
    }

    if (config.strategies != null && config.strategies.length > 0) {
        // eslint-disable-next-line no-loop-func
        config.strategies.forEach((fs) => {
            if (!fs.key || fs.key.startsWith('_')) return;

            if (fs?.metaData?.outputs != null) {
                fs.metaData.outputs.forEach((element) => {
                    if (!environment.replacementSuggestions.filter((p) => config.label == element.name).length > 0) {
                        environment.replacementSuggestions.push({
                            page: null,
                            label: element.name,
                            kind: monaco.languages.CompletionItemKind.Function,
                            documentation: element.description,
                            insertText: element.name,
                        });
                    }
                });
            }
            var insertTextArray = [` ${fs.key}`];
            if (fs.value.replacements) {
                // insertTextArray.push('$[TAB]replacements:');

                Object.keys(fs.value.replacements).forEach((rKey) => {
                    var text = convertObjectToYaml(fs.value.replacements[rKey]);
                    insertTextArray.push(`  ${rKey}: ${text === 'null' ? '' : text}`);
                });
            }

            var page = key.indexOf('proxies') > -1 ? 'system' : key;

            var strategy = {
                page: page,
                label: fs.key,
                kind: monaco.languages.CompletionItemKind.Function,
                // documentation: 'Tabs stuff',
                insertText: insertTextArray.join('\r\n'),
            };

            // if (currentSubDomain != null) {
            //     currentSubDomain.strategySuggestions.push(strategy);
            //     return;
            // }
            environment.strategySuggestions.push(strategy);
        });
    }
};
