import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { fileIconSelect } from '../FileIconSelect';
import { decodeDisabled, parseBool } from '../../services/globalMethods';
import { BpSpinner } from '../BpSpinner';
import { deepCopy } from '../forms/formFunctions';
import { substitute } from '../../services/stringsubstitutions';

function escapeHtml(value) {
    const div = document.createElement('div');
    div.appendChild(document.createTextNode(value));
    return div.innerHTML;
}

function renderInputOrTextarea(value, id) {
    const stringValue = value !== null && value !== undefined ? value.toString() : ''; // Ensure value is a string
    const isMultiline = stringValue.includes('\n'); // Check if there's any newline in the value

    // Render as <textarea> if multiline, otherwise as <input>
    if (isMultiline) {
        return (
            <textarea
                id={id}
                rows="1"
                oninput="autoResize(this)"
                onblur="switchToInput('${id}')"
                onkeypress="checkForEnter(event, '${id}')">
                {stringValue}
            </textarea>
        );
    } else {
        return (
            <input
                id={id}
                type="text"
                value={stringValue}
                oninput="checkForNewline('${id}')"
                onkeypress="checkForEnter(event, '${id}')"
            />
        );
    }
}

function checkForEnter(event, id) {
    if (event.key === 'Enter') {
        event.preventDefault(); // Prevent the default form submission behavior
        const element = document.getElementById(id);
        const currentValue = element.value;

        // Add the newline (\n) to the current value
        const newValue = currentValue + '\n';

        // Replace the input with a textarea, and insert the new value (with \n)
        switchToTextarea(id, newValue);
    }
}

function checkForNewline(id) {
    const element = document.getElementById(id);
    const value = element.value;

    // If the value contains a newline, switch to textarea
    if (value.includes('\n')) {
        switchToTextarea(id, value);
    }
}

function switchToTextarea(id, value = '') {
    const element = document.getElementById(id);
    const textareaElement = document.createElement('textarea');

    textareaElement.id = id;
    textareaElement.value = value;
    textareaElement.rows = 1; // Start with minimal rows
    textareaElement.style.overflowY = 'hidden'; // Hide vertical scrollbar
    textareaElement.oninput = function () {
        autoResize(this); // Continue checking for newlines and resize as needed
    };
    textareaElement.onblur = function () {
        switchToInput(id);
    };
    textareaElement.onkeypress = function (event) {
        checkForEnter(event, id);
    };

    element.parentNode.replaceChild(textareaElement, element); // Replace input with textarea
    autoResize(textareaElement); // Adjust height initially
    textareaElement.focus();
}

function switchToInput(id) {
    const element = document.getElementById(id);
    const value = element.value;

    // Switch back to input if no newline is present
    if (!value.includes('\n')) {
        const inputElement = document.createElement('input');
        inputElement.id = id;
        inputElement.type = 'text';
        inputElement.value = value;
        inputElement.oninput = function () {
            checkForNewline(id);
        };
        inputElement.onkeypress = function (event) {
            checkForEnter(event, id);
        };

        element.parentNode.replaceChild(inputElement, element); // Replace textarea with input
        inputElement.focus();
    }
}

// Function to adjust the textarea height to fit content
function autoResize(textarea) {
    textarea.style.height = 'auto'; // Reset height to allow shrinking
    textarea.style.height = textarea.scrollHeight + 'px'; // Set height to fit content
}

const renderPipe = (pipeData, index) => {
    var condition = '';
    var errorPipeClass = '';
    var conditionEnd = '';
    var conditionBodyClass = '';
    var continuationPolicy = '';
    if (pipeData.CAN_EXECUTE_EXPRESSION) {
        condition = (
            <span
                style={{ color: 'purple', fontSize: '13px', fontWeight: 'bold', fontStyle: 'italic' }}
                className="condition-header">
                IF({pipeData.CAN_EXECUTE_EXPRESSION})
            </span>
        );
        conditionBodyClass = 'condition-body';
    }

    if (pipeData.pipe.toLowerCase().includes('throw')) errorPipeClass = 'error-pipe';
    // ReturnIfExecuted | ContinueOnNotExecuted | ContinueOnError | ReturnOnSuccess | ReturnIfNotExecuted
    switch (pipeData.CONTINUATION_POLICY) {
        case 'ReturnIfExecuted':
            continuationPolicy = 'RETURN;';
            break;
        default:
            continuationPolicy = '';
    }
    return (
        <>
            <div class="pipe-section" id="pipe-${index}">
                {/* <div class="comment-dropdown-container">
                    <input type="text" value={pipeData.comments ?? '# '} placeholder="Add a comment..." />
                    <div class="action-dropdown">
                        <select>
                            <option value="">Actions...</option>
                            <option value="addProperty">Add Property</option>
                            <option value="deletePipe">Delete Pipe</option>
                        </select>
                    </div>
                </div> */}

                <div class="condition-section">
                    {condition}
                    <div className={conditionBodyClass}>
                        <div class="pipe-header ${errorPipeClass}">
                            <h3>PIPE: {pipeData.pipe}</h3>
                            <button class="toggle-button" onclick="toggleVisibility('content-${index}', 'toggle-icon-${index}')">
                                <span id="toggle-icon-${index}">▼</span>
                            </button>
                        </div>

                        <div>
                            {WriteSettings(pipeData, index)}
                            <div class="property">
                                <span class="property-key" style={{ color: 'blue' }}></span>
                            </div>
                        </div>
                        <div class="continuation-policy">{continuationPolicy}</div>
                    </div>
                    {condition !== '' ? <span class="condition-end">{'}'}</span> : ''}
                </div>
            </div>
            <div class="pipe-separator"></div>
        </>
    );
};

function WriteSettings(pipe, index) {
    if (pipe.replacements || pipe.defaults)
        return (
            <>
                <div>
                    {' '}
                    replacements:<div class="replacements">{WritePipe(pipe.replacements ?? {}, index)} </div>
                </div>
                <div>
                    {' '}
                    defaults:<div class="replacements"> {WritePipe(pipe.defaults ?? {}, index)} </div>
                </div>
            </>
        );

    return WritePipe(pipe, index);
}

function WritePipe(pipe, index) {
    return Object.keys(pipe).map((key) => {
        if (key === 'comments') {
            return '';
        } else if (key !== 'pipe' && !key.endsWith('_PIPES') && !key.endsWith('PIPELINE')) {
            return (
                <div class="property">
                    <span class="property-key">{key}:</span>
                    <span class="property-value">{renderInputOrTextarea(pipe[key], `{pipe.pipe}-${key}`)}</span>
                    <span class=""></span>
                </div>
            );
            // } else if (key.endsWith('_PIPE') && !Array.isArray(pipe[key])) {
            //     debugger;
            //     return (
            //         <>
            //             <div class="scoped-property-key">{key}:</div>
            //             <div class="nested-section">{renderPipe(pipe, index)}</div>
            //         </>
            //     );
        } else if ((key.endsWith('_PIPES') || key.toUpperCase().endsWith('PIPELINE')) && Array.isArray(pipe[key])) {
            return (
                <>
                    <div class="scoped-property-key">{key}:</div>
                    <div class="nested-section">{pipe[key].map((subPipe, i) => renderPipe(subPipe, `${index}-${i}`))}</div>
                </>
            );
        }
        return '';
    });
}

const propertiesHtml = (properties) => {
    return Object.keys(properties).map((key) => {
        if (key === 'pipeline') return '';
        var val = (
            <div class="property">
                <span class="property-key">{key.toUpperCase()}:</span>
                <span class="property-value">
                    <input type="text" value={escapeHtml(properties[key])} />
                </span>
            </div>
        );
        return val;
    });
};

const renderEndPoints = (endPoint, index) => {
    return (
        <div className="endpoint-section" id="endpoint-${index}">
            <div class="endpoint-header">
                <h2>
                    {endPoint.routeMethod} /{endPoint.routeTemplate}
                </h2>
            </div>

            {propertiesHtml(endPoint)}

            <div class="pipeline-section">
                <div class="property">
                    <span class="property-key">PIPELINE:</span>
                </div>

                {/* <div class="pipeline-header">
                    <h4>PIPELINE:</h4>
                    <button
                        class="toggle-button"
                        onclick="toggleVisibility('pipeline-content-${index}', 'pipeline-icon-${index}')">
                        <span id="pipeline-icon-${index}">▼</span>
                    </button>
                </div> */}
                <div className="pipeline-content">
                    <div class="collapsible-content" id="pipeline-content-${index}">
                        {endPoint.pipeline.map((pipe, pipeIndex) => renderPipe(pipe, `${index}-${pipeIndex}`))}
                    </div>
                </div>
            </div>
        </div>
    );
};

const renderKeyValues = (record, index) => {
    return (
        <div class="endpoint-section" id="endpoint-${index}">
            <div class="endpoint-header" style={{ marginTop: '10px', marginBottom: '5px' }}>
                <h3>KEY: {record.key}</h3>
            </div>
            <h3 style={{ marginTop: '10px', marginBottom: '5px' }}>VALUE: </h3>
            <div style={{ margin: '10px' }}>
                <span>
                    {record.value.pipe}
                    {record.value.connector}
                    {record.value.strategy}
                </span>
                {WriteSettings(record.value, index)}
            </div>
            {/* {renderPipe(record.value, `${index}-1`)} */}
        </div>
    );
};

const renderConnections = (record, index) => {
    return (
        <div className="endpoint-section" id="endpoint-${index}">
            <div class="endpoint-header">
                <h2>{record.connector}</h2>
            </div>

            {propertiesHtml(record)}

            <div class="pipeline-section">
                <div class="property">
                    <span class="property-key">PIPELINE:</span>
                </div>

                {/* <div class="pipeline-header">
            <h4>PIPELINE:</h4>
            <button
                class="toggle-button"
                onclick="toggleVisibility('pipeline-content-${index}', 'pipeline-icon-${index}')">
                <span id="pipeline-icon-${index}">▼</span>
            </button>
        </div> */}
                <div className="pipeline-content">
                    <div class="collapsible-content" id="pipeline-content-${index}">
                        {record.pipeline.map((pipe, pipeIndex) => renderPipe(pipe, `${index}-${pipeIndex}`))}
                    </div>
                </div>
            </div>
        </div>
    );

    // return (
    //     <div class="endpoint-section" id="endpoint-${index}">
    //         <div class="endpoint-header">
    //             <h3>{record.connector}</h3>
    //         </div>

    //         {Object.keys(record)
    //             .filter((key) => key !== 'pipeline')
    //             .map((key) => (
    //                 <div class="property">
    //                     <span class="property-key">{key.toUpperCase()}:</span>
    //                     <span class="property-value">
    //                         <input type="text" value={escapeHtml(record[key])} />
    //                     </span>
    //                 </div>
    //             ))}

    //         <div class="pipeline-section">
    //             <div class="pipeline-header">
    //                 <h4>PIPELINE:</h4>
    //                 <button
    //                     class="toggle-button"
    //                     onclick="toggleVisibility('pipeline-content-${index}', 'pipeline-icon-${index}')">
    //                     <span id="pipeline-icon-${index}">▼</span>
    //                 </button>
    //             </div>

    //             <div class="collapsible-content" id="pipeline-content-${index}">
    //                 <div class="pipe-separator">
    //                     <button></button>
    //                 </div>
    //                 {record.pipeline.map((pipe, pipeIndex) => renderPipe(pipe, `${index}-${pipeIndex}`))}
    //             </div>
    //         </div>
    //     </div>
    // );
};

const HubFormDesigner = (props) => {
    var { controller, layoutItem, collectionStack, formGenerator } = props;
    const [currentContents, setCurrentContents] = useState(null);
    const [isExpanded, setIsExpanded] = useState(false);

    // Toggle the palette expansion state when the button is clicked
    const togglePalette = () => {
        setIsExpanded((prev) => !prev);
    };

    useEffect(() => {
        // Log whenever the palette state changes
        console.log(`Palette is now ${isExpanded ? 'expanded' : 'collapsed'}`);
    }, [isExpanded]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                var data =
                    typeof layoutItem.key === 'string' ? await controller.props.dataStore.get(layoutItem.key) : layoutItem.key;

                setCurrentContents(data ? data : null);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [controller.props.dataStore, layoutItem.key]);

    if (currentContents == null) return <div>Invalid file type</div>;

    var endPoints = [];
    var connections = [];
    var pipes = [];
    var connectors = [];
    var strategies = [];
    if (currentContents?.connections != null) {
        connections = currentContents?.connections.map((connection, index) => {
            return renderConnections(connection, index);
        });
    }

    if (currentContents?.endPoints != null) {
        endPoints = currentContents?.endPoints.map((endPoint, index) => {
            return renderEndPoints(endPoint, index);
        });
    }

    if (currentContents?.pipes != null) {
        pipes = currentContents?.pipes.map((pipeKv, index) => {
            return renderKeyValues(pipeKv, index);
        });
    }

    if (currentContents?.connectors != null) {
        connectors = currentContents?.connectors.map((connector, index) => {
            return renderKeyValues(connector, index);
        });
    }
    if (currentContents?.connectors != null) {
        strategies = currentContents?.strategies.map((strategy, index) => {
            return renderKeyValues(strategy, index);
        });
    }

    return (
        <div className="hub-form-designer">
            <div style={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
                <div className="designer-container">
                    <div className="main-section">
                        <h1>Listeners</h1>
                        <div>{connections}</div>
                    </div>{' '}
                    <div className="main-section">
                        <h1>Endpoints</h1>
                        <div>{endPoints}</div>
                    </div>
                    <div className="main-section">
                        <h1>Pipes</h1>
                        <div>{pipes}</div>
                    </div>
                    <div className="main-section">
                        <h1>Connectors</h1>
                        <div>{connectors}</div>
                    </div>
                    <div className="main-section">
                        <h1>Strategies</h1>
                        <div>{strategies}</div>
                    </div>
                </div>
                <div className={`palette ${isExpanded ? 'expanded' : 'collapsed'}`}>
                    {/* Toggle button */}
                    <button onClick={togglePalette} className="toggle-button">
                        {isExpanded ? '«' : '»'}
                    </button>
                    {isExpanded && (
                        <div className="content" style={{ minWidth: '300px' }}>
                            <h2>Tools</h2>
                            <ul>
                                <li>Tool 1</li>
                                <li>Tool 2</li>
                                <li>Tool 3</li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default HubFormDesigner;
