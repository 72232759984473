import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { aquireToken, clearStoredUrl, getRedirectUrl, getToken, storeRedirectUrl } from './adal';
import './App.css';
import './extensions/pages/factory';
import Portal from './controls/system/Portal';
import SplashScreen from './controls/system/SplashScreen';
import './css/bp-layout.css';
import './css/contextpanel.css';
import './css/multienvs.css';
import './css/new-layout.css';
import './css/forms.css';
import './css/mainForm.css';
import './css/myThemes.css';
import './services/json2Yaml.js';
import { pageChanged } from './redux/actions';
import '.';
import { ThemeContext } from './controls/system/ThemeContext';
import { themes } from './css/theme';
import './cssnew/common-styles.css';
import './cssnew/cardsAndFeatures.css';
import './cssnew/sideMenu.css';
import './cssnew/tabs.css';
import './cssnew/underwriteme.css';
import './cssnew/expander.css';
import './cssnew/parentChildDetails.css';
import './cssnew/main-layout.css';
import './cssnew/hub-file-explorer-base.css';
import './cssnew/hub-form-designer.css';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            theme: { ...themes },
            hideMenu: false,
        };
    }

    componentDidMount() {
        this.init();
    }

    finish = () => {
        this.setState({ ready: true });
        let localStoragePath = getRedirectUrl();
        if (localStoragePath) {
            clearStoredUrl();
            if (this.props.location.search) {
                localStoragePath += this.props.location.search;
            }
            this.props.dispatch(pageChanged(localStoragePath));
            this.props.history.push(localStoragePath);
        }
    };

    init = async () => {
        storeRedirectUrl();
        const initialToken = getToken();
        if (!initialToken) {
            const aquiredToken = await aquireToken();
            if (aquiredToken) {
                this.finish();
            } else {
                storeRedirectUrl();
                // // authContext.login();
            }
        } else {
            this.finish();
        }
    };

    toggleMenu = () => {
        this.setState((state) => (this.state.hideMenu = !state.hideMenu));
    };

    render() {
        const { ready } = this.state;
        if (ready) {
            return (
                <ThemeContext.Provider value={this.state.theme}>
                    <Portal hideMenu={this.state.hideMenu} onHideMenu={this.toggleMenu} />;
                </ThemeContext.Provider>
            );
        }
        return <SplashScreen />;
    }
}

export default withRouter(connect(null, null)(App));
