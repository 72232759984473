import React from 'react';
import { substitute } from '../services/stringsubstitutions';
import { parseBool } from '../services/globalMethods';
import { getFieldFromFormSection } from './forms/formFunctions';
import { ContainerBlank } from './containers/ContainerBlank';

import HubTable from './hub/HubTable';
import HubCommandBar from './hub/HubCommandBar';
import HubFormGenerator from './hub/HubFormGenerator';
import { HubProperties } from './hub/HubProperties';
// import { HubImage } from './hub/HubImage';
import HubNavigationLink from './hub/HubNavigationLink';
import { HubMessageBar } from './hub/HubMessageBar';
import { HubGraphDoughnut } from './hub/HubGraphDoughnut';
import { HubGraphBarChart } from './hub/HubGraphBarChart';
import { HubCustom } from './hub/HubCustom';
import HubFileExplorer from './hub/HubFileExplorer';
import HubFormDesigner from './hub/HubFormDesigner';

const where = (collection, constraint) => {
    return collection.filter((collectionItem) =>
        Object.keys(constraint).every((key) => collectionItem.hasOwnProperty(key) && constraint[key] === collectionItem[key])
    );
};

export const getLayout = (
    controller,
    layoutItem,
    layoutItemIndex,
    isLastItem,
    collectionStack,
    parentSettings,
    formGenerator
) => {
    var props = controller.props;
    var config = props.page.config;
    if (collectionStack == null) collectionStack = [];

    const layoutKey = `${layoutItemIndex}-${layoutItem.type}-${layoutItem.methodName}-${layoutItem.key}`;
    if (layoutItem.canExecute != null) {
        if (layoutItem.canExecute.isNull != null && props.dataStore[layoutItem.canExecute.isNull] != null) return null;

        if (layoutItem.canExecute.isNotNull != null && props.dataStore[layoutItem.canExecute.isNotNull] == null) return null;

        if (layoutItem.canExecute.isSelected != null) {
            var selectedItems = props.selectedItems;
            if (
                layoutItem.canExecute.isSelected != null &&
                layoutItem.canExecute.isSelected &&
                selectedItems != null &&
                selectedItems.length === 0
            ) {
                return null;
            }

            if (
                layoutItem.canExecute.isSelected != null &&
                !layoutItem.canExecute.isSelected &&
                (selectedItems == null || selectedItems.length > 0)
            ) {
                return null;
            }
            var result = where(selectedItems, layoutItem.canExecute);
            if (!result) {
                return null;
            }
        }
    }

    var title = null;
    if (layoutItem.title != null) {
        var text = substitute(layoutItem.title, controller, [layoutItem, parentSettings]);
        title = (
            <span className="layout-title">
                <span className="spacer" dangerouslySetInnerHTML={{ __html: text }} />
            </span>
        );
    }

    var ctrl = null;
    var ignoreLayoutStyles = layoutItem.settings && parseBool(layoutItem.settings.ignoreLayoutStyles, false);
    switch (layoutItem.type.toLowerCase()) {
        case 'navigationlink':
            ctrl = (
                <HubNavigationLink
                    controller={controller}
                    config={config}
                    layoutItem={layoutItem}
                    collectionStack={collectionStack}
                    formGenerator={formGenerator}
                />
            );
            ignoreLayoutStyles = true;
            break;
        case 'formsection':
            var formItem = getFieldFromFormSection(controller, layoutItem);
            formItem.separator = false;
            ctrl = getLayout(controller, formItem, layoutItemIndex, isLastItem, collectionStack, parentSettings, formGenerator);
            //  separatorEnabled = false;
            break;
        // case 'stack':
        //     ctrl = (
        //         <LayoutStack
        //             controller={controller}
        //             config={config}
        //             layoutItem={layoutItem}
        //             collectionStack={collectionStack}
        //             formGenerator={formGenerator}
        //         />
        //     );
        //     break;
        case 'blankcollection':
            ctrl = (
                <ContainerBlank
                    controller={controller}
                    config={config}
                    layoutItem={layoutItem}
                    collectionStack={collectionStack}
                    formGenerator={formGenerator}
                />
            );
            // separatorEnabled = false;
            break;
        case 'graph.doughnut':
            ctrl = (
                <HubGraphDoughnut
                    controller={controller}
                    config={config}
                    layoutItem={layoutItem}
                    collectionStack={collectionStack}
                    formGenerator={formGenerator}
                />
            );
            break;
        case 'graph.barchart':
            ctrl = (
                <HubGraphBarChart
                    controller={controller}
                    config={config}
                    layoutItem={layoutItem}
                    collectionStack={collectionStack}
                    formGenerator={formGenerator}
                />
            );
            break;
        case 'separator':
            ctrl = null;
            title = null;
            //      separatorEnabled = true;
            break;
            // case 'label':
            //     ctrl = (
            //         <LayoutLabel
            //             controller={controller}
            //             config={config}
            //             layoutItem={layoutItem}
            //             collectionStack={collectionStack}
            //             formGenerator={formGenerator}
            //         />
            //     );

            //     //     separatorEnabled = false;
            break;
        // case 'image':
        //     ctrl = (
        //         <HubImage
        //             controller={controller}
        //             config={config}
        //             layoutItem={layoutItem}
        //             collectionStack={collectionStack}
        //             formGenerator={formGenerator}
        //         />
        //     );
        //     //    separatorEnabled = false;
        //     ignoreLayoutStyles = true;
        //     break;
        case 'properties':
            ctrl = (
                <HubProperties
                    controller={controller}
                    config={config}
                    layoutItem={layoutItem}
                    collectionStack={collectionStack}
                    formGenerator={formGenerator}
                />
            );
            break;
        case 'commandbar':
            ctrl = (
                <HubCommandBar
                    controller={controller}
                    config={config}
                    layoutItem={layoutItem}
                    collectionStack={collectionStack}
                    formGenerator={formGenerator}
                />
            );
            //           separatorEnabled = false;
            break;
        case 'custom':
            ctrl = (
                <HubCustom
                    controller={controller}
                    config={config}
                    layoutItem={layoutItem}
                    collectionStack={collectionStack}
                    formGenerator={formGenerator}
                />
            );
            break;
        case 'form':
            ctrl = (
                <HubFormGenerator
                    controller={controller}
                    config={config}
                    layoutItem={layoutItem}
                    collectionStack={collectionStack}
                    formGenerator={formGenerator}
                />
            );
            ignoreLayoutStyles = true;
            break;
        case 'blank':
        case 'spacer':
            ctrl = <div className={layoutItem.class} style={layoutItem.styles}></div>;
            title = null;
            //        separatorEnabled = false;
            break;
        case 'messagebar':
            ctrl = (
                <HubMessageBar
                    controller={controller}
                    config={config}
                    layoutItem={layoutItem}
                    collectionStack={collectionStack}
                    formGenerator={formGenerator}
                />
            );
            ignoreLayoutStyles = true;
            break;
        case 'table':
            var itm = null;
            if (parseBool(layoutItem.settings && layoutItem.settings.useSelectedItem, false)) itm = controller.props.selectedItem;

            ctrl = (
                <HubTable
                    controller={controller}
                    config={config}
                    layoutItem={layoutItem}
                    data={itm}
                    collectionStack={collectionStack}
                    formGenerator={formGenerator}
                />
            );
            break;

        case 'formdesigner':
            ctrl = (
                <HubFormDesigner
                    controller={controller}
                    config={config}
                    layoutItem={layoutItem}
                    data={itm}
                    collectionStack={collectionStack}
                    formGenerator={formGenerator}
                />
            );
            break;
        case 'fileexplorer':
            ctrl = (
                <HubFileExplorer
                    controller={controller}
                    config={config}
                    layoutItem={layoutItem}
                    data={itm}
                    collectionStack={collectionStack}
                    formGenerator={formGenerator}
                />
            );
            break;
        // case 'separator':
        //     ctrl = null;
        //     title = null;
        //     break;
        default:
            debugger;
            ctrl = `LayoutItem.type: ${layoutItem.type} not found`;
            break;
    }

    return (
        <React.Fragment key={layoutKey}>
            <>
                {title}
                {ctrl}
            </>
            {/* {ignoreLayoutStyles ? (
                <>
                    {title}
                    {ctrl}
                </>
            ) : (
                <span style={layoutStyles} className={layoutClass}>
                    {title}
                    {ctrl}
                </span>
            )} */}
            {/* <HubSeparator enabled={separatorEnabled} controller={controller} config={config} layoutItem={layoutItem} /> */}
        </React.Fragment>
    );
};
